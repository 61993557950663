<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(submitForm)">
      <v-row>
        <v-col cols="12" sm="6" md="2">
          <v-menu
            ref="menu"
            v-model="datePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider v-slot="{ errors }" name="date" rules="required">
                <v-text-field
                  :value="dateFormatted"
                  :label="`${$t('fields.date')} *`"
                  :prepend-inner-icon="mdiCalendar"
                  :hint="$t('wot.formats.date')"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="errors"
                  outlined
                  dense
                  readonly
                />
              </validation-provider>
            </template>
            <v-date-picker
              v-model="date"
              @input="datePicker = false"
              no-title
              show-adjacent-months
              first-day-of-week="1"
            >
              <v-btn text block color="primary" @click="clickToday()">{{ $t('today') }}</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <validation-provider
            v-slot="{ errors }"
            name="startHour"
            rules="required|regex:[0-9]{2}:[0-9]{2}"
          >
            <v-text-field
              v-model="startHour"
              :label="`${$t('fields.startHour')} *`"
              v-mask="'##:##'"
              :prepend-inner-icon="mdiClockOutline"
              :hint="$t('wot.formats.hourMin')"
              persistent-hint
              :error-messages="errors"
              outlined
              dense
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <validation-provider
            v-slot="{ errors }"
            name="endHour"
            rules="required|regex:[0-9]{2}:[0-9]{2}">
            <v-text-field
              v-model="endHour"
              :label="`${$t('fields.endHour')} *`"
              v-mask="'##:##'"
              :prepend-inner-icon="mdiClockOutline"
              :hint="$t('wot.formats.hourMin')"
              persistent-hint
              :error-messages="errors"
              outlined
              dense
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="5" lg="3">
          <validation-provider v-slot="{ errors }" name="technician" rules="required">
            <v-autocomplete
              v-model="technician"
              :items="technicians"
              item-text="cmdesc"
              item-value="cm4stamp"
              :label="`${$t('fields.technician')} *`"
              :prepend-inner-icon="mdiAccountTie"
              :error-messages="errors"
              outlined
              dense
              clearable
              :loading="isLoading"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider v-slot="{ errors }" name="report" rules="required">
            <v-textarea
              v-model="report"
              :label="`${$t('fields.report')} *`"
              :prepend-inner-icon="mdiText"
              :error-messages="errors"
              counter="10000"
              maxlength="10000"
              outlined
              dense
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            color="secondary"
            v-model="fulfilled"
            :label="`${$t('fields.fulfilled')}?`"
          />
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" type="submit" :loading="isLoading" :disabled="isLoading">
            {{ btnText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueMaskDirective } from 'v-mask';
import {
  mdiCalendar,
  mdiClockOutline,
  mdiText,
  mdiAccountTie,
} from '@mdi/js';
import dayjs from '@/plugins/dayjs';
import helpers from '@/resources/helpers';

export default {
  metaInfo() {
    return {
      title: this.mhstamp ? `${this.$tc('intervention', 1)} #${this.interventionInfo.mhid}` : this.$t('createIntervention'),
    };
  },
  directives: { mask: VueMaskDirective },
  mixins: [helpers],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    pastamp: {
      type: String,
      required: true,
    },
    mhstamp: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    datePicker: false,
    mdiCalendar,
    mdiClockOutline,
    mdiText,
    mdiAccountTie,
  }),
  computed: {
    ...mapFields({ interventionFormErrors: 'errors.intervention', isLoading: 'isLoading', alertType: 'alertType' }),
    ...mapFields(
      'support',
      [
        'technicians',
        'interventionInfo',
        'interventionForm.date',
        'interventionForm.startHour',
        'interventionForm.endHour',
        'interventionForm.technician',
        'interventionForm.report',
        'interventionForm.fulfilled',
      ],
    ),
    btnText() {
      return this.pastamp ? this.$t('aSave') : this.$t('create');
    },
    dateFormatted() {
      return this.formatDate(this.date);
    },
  },
  methods: {
    ...mapActions('support', ['fetchIntervention', 'createIntervention', 'editIntervention', 'fetchPat']),
    clickToday() {
      this.date = dayjs().format('YYYY-MM-DD');
    },
    async submitForm() {
      this.isLoading = true;

      if (this.mhstamp) {
        await this.editIntervention(this.mhstamp);
      } else {
        await this.createIntervention(this.pastamp);

        if (this.alertType === 'success') {
          this.$router.push({ name: 'patInfo', params: { pastamp: this.pastamp } });
        }
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.fetchPat(this.pastamp);

    this.activePastamp = this.pastamp;

    if (this.mhstamp) {
      await this.fetchIntervention(this.mhstamp);

      const {
        data,
        hora,
        horaf,
        relatorio,
        realizada,
        cm4,
      } = this.interventionInfo;

      this.date = data;
      this.startHour = hora;
      this.endHour = horaf;
      this.technician = cm4?.cm4stamp;
      this.report = relatorio;
      this.fulfilled = realizada;
    } else {
      this.date = dayjs().format('YYYY-MM-DD');
      this.startHour = dayjs().format('HH:mm');
      this.endHour = dayjs().format('HH:mm');
      this.technician = JSON.parse(localStorage.getItem('config'))?.technician;
      this.report = '';
      this.fulfilled = false;
    }

    this.isLoading = false;
  },
  watch: {
    interventionFormErrors: {
      deep: true,
      handler(value) {
        this.$refs.observer.setErrors(value);
      },
    },
  },
};
</script>
