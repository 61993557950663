var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('pats-filter',{on:{"filter":_vm.getPatsList}}),_c('v-card',{staticClass:"mt-1",attrs:{"elevation":"24"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.mdiMagnify,"label":_vm.$t('aSearch'),"counter":"50","maxlength":"50","clearable":"","outlined":"","rounded":"","dense":""},on:{"change":_vm.getPatsList},model:{value:(_vm.patsSearch),callback:function ($$v) {_vm.patsSearch=$$v},expression:"patsSearch"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.patsList,"loading":_vm.isLoading,"loading-text":_vm.$t('wot.loading'),"options":_vm.options,"server-items-length":_vm.patsListTotal,"footer-props":{
          showFirstLastPage: true,
          'items-per-page-options': _vm.itemsPerPage
        },"mobile-breakpoint":0,"height":"80vh","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.pdata",fn:function(ref){
        var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDate(value)))]}},{key:"item.nome",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" "),_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(((_vm.$t('shortNumber')) + " " + (item.no))))])]}},{key:"item.fechado",fn:function(ref){
        var value = ref.value;
return [_c('v-simple-checkbox',{attrs:{"value":value,"disabled":""}})]}},{key:"item.mh_sum_moh",fn:function(ref){
        var value = ref.value;
return [_vm._v(" "+_vm._s(value || 0)+" ")]}},{key:"item.inter",fn:function(ref){
        var item = ref.item;
return [(item.mh_count > 0)?_c('span',{class:item.done === item.mh_count ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(item.done)+"/"+_vm._s(item.mh_count)+" ")]):_c('span',[_vm._v("0")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","icon":"","to":{ name: 'patInfo', params: { pastamp: item.pastamp } }}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiFormatListBulletedSquare))])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }