<template>
  <v-card elevation="24">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form>
          <v-row>
            <v-col cols="12" sm="6" md="2">
              <v-menu
                ref="menu"
                v-model="datePicker"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider v-slot="{ errors }" name="month" rules="required">
                    <v-text-field
                      :value="dateFormatted"
                      :label="`${$t('fields.month')} *`"
                      :prepend-inner-icon="mdiCalendar"
                      :hint="$t('wot.formats.month')"
                      persistent-hint
                      v-bind="attrs"
                      v-on="on"
                      :error-messages="errors"
                      outlined
                      dense
                      readonly
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="month"
                  @input="datePicker = false"
                  no-title
                  show-adjacent-months
                  first-day-of-week="1"
                  type="month"
                >
                  <v-btn text block color="primary" @click="clickToday()">
                    {{ $t('currentMonth') }}
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import { mdiCalendar } from '@mdi/js';
import dayjs from '@/plugins/dayjs';

export default {
  emits: ['filter'],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    datePicker: false,
    mdiCalendar,
  }),
  computed: {
    ...mapGetters('chart', ['interventionsFilter']),
    ...mapFields('chart', { month: 'filters.interventions.month' }),
    dateFormatted() {
      return dayjs(this.month).format('MM/YYYY');
    },
  },
  methods: {
    clickToday() {
      this.month = dayjs().format('YYYY-MM-DD');
    },
  },
  watch: {
    interventionsFilter: {
      deep: true,
      handler() {
        this.$emit('filter');
      },
    },
  },
};
</script>
