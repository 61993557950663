<template>
  <div>
    <pat-info-filter :pastamp="pastamp"/>
    <v-card elevation="24" class="mt-1">
      <v-card-title class="light-blue lighten-5">
        {{ patInfo.resumo }}
        <v-spacer/>
          <v-btn icon color="primary" :to="{ name: 'editPat', params: { pastamp } }">
            <v-icon>{{ mdiSquareEditOutline }}</v-icon>
          </v-btn>
          <v-btn icon color="error" @click="deleteDialog = true">
            <v-icon>{{ mdiDeleteOutline }}</v-icon>
          </v-btn>
      </v-card-title>
      <v-card-subtitle class="light-blue lighten-5 text-subtitle-1">
        <v-icon>{{ mdiCalendar }}</v-icon> {{ formatDate(patInfo.pdata) }}
      </v-card-subtitle>
      <v-card-text class="text-subtitle-1 mt-5">
        <p v-for="(item, index) in paragraphs" :key="index">
          <template
            v-if="clstamp !== 'S09012858152,7500000-1'
            || item.field !== $t('fields.whoAsk')"
          >
            <span class="text-subtitle-2">{{ item.field }}:</span>
            {{ item.value }}
          </template>
        </p>
      </v-card-text>
    </v-card>
    <v-card elevation="24" class="mt-1">
      <v-card-title class="light-blue lighten-5">{{ $tc('intervention', 2) }}</v-card-title>
      <v-card-text class="mt-5">
        <pat-interventions
          @delete-intervention="getPat()"
          :interventions="patInfo.mh"
          :pastamp="pastamp"
        />
      </v-card-text>
    </v-card>
    <wot-confirm v-if="deleteDialog" :del="true" @confirm-action="submitDelete">
      {{ $t('wot.delete') }}
    </wot-confirm>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { mdiCalendar, mdiSquareEditOutline, mdiDeleteOutline } from '@mdi/js';
import helpers from '@/resources/helpers';

export default {
  metaInfo() {
    return {
      title: `${this.$tc('patShort', 1)} #${this.patInfo.nopat}`,
    };
  },
  mixins: [helpers],
  components: {
    WotConfirm: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotConfirm.vue'),
    PatInfoFilter: () => import(/* webpackChunkName: "support" */ '@/components/filters/PatInfoFilter.vue'),
    PatInterventions: () => import(/* webpackChunkName: "support" */ '@/components/support/PatInterventions.vue'),
  },
  props: {
    pastamp: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    deleteDialog: false,
    mdiCalendar,
    mdiSquareEditOutline,
    mdiDeleteOutline,
  }),
  computed: {
    ...mapFields(['isLoading', 'alertType']),
    ...mapFields('support', ['patInfo']),
    clstamp() {
      return this.patInfo.cl?.clstamp;
    },
    paragraphs() {
      const {
        nome,
        no,
        ncont,
        telefone,
        remail,
        pquem,
        problema,
        just,
        morada,
        codpost,
        local,
        ousrinis,
        tecnnm,
      } = this.patInfo;

      return [
        {
          field: this.$t('fields.client'),
          value: `${nome} (${this.$t('shortNumber')} ${no})`,
        },
        { field: this.$t('fields.vatNumber'), value: ncont },
        { field: this.$t('fields.phone'), value: telefone },
        { field: this.$t('fields.email'), value: remail },
        {
          field: this.$t('fields.address'), value: `${morada}, ${codpost} ${local}`,
        },
        { field: this.$t('fields.whoAsk'), value: pquem },
        { field: this.$t('fields.whoOpen'), value: ousrinis },
        { field: this.$t('fields.description'), value: problema },
        { field: this.$t('fields.technician'), value: tecnnm },
        { field: this.$t('fields.internalComunication'), value: just },
      ];
    },
  },
  methods: {
    ...mapActions('support', ['fetchPat', 'deletePat']),
    async getPat() {
      this.isLoading = true;

      await this.fetchPat(this.pastamp);

      this.isLoading = false;
    },
    async submitDelete(confirm) {
      if (confirm) {
        this.isLoading = true;

        await this.deletePat(this.pastamp);

        if (this.alertType === 'success') {
          this.$router.push({ name: 'pats' });
        }

        this.isLoading = false;
      }

      this.deleteDialog = false;
    },
  },
  async mounted() {
    this.getPat();
  },
};
</script>
