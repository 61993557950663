var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateFormatted,"label":((_vm.$t('fields.date')) + " *"),"prepend-inner-icon":_vm.mdiCalendar,"hint":_vm.$t('wot.formats.date'),"persistent-hint":"","error-messages":errors,"outlined":"","dense":"","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","show-adjacent-months":"","first-day-of-week":"1"},on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-btn',{attrs:{"text":"","block":"","color":"primary"},on:{"click":function($event){return _vm.clickToday()}}},[_vm._v(_vm._s(_vm.$t('today')))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('validation-provider',{attrs:{"name":"hour","rules":"required|regex:[0-9]{2}:[0-9]{2}"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],attrs:{"label":((_vm.$t('fields.hour')) + " *"),"prepend-inner-icon":_vm.mdiClockOutline,"hint":_vm.$t('wot.formats.hourMin'),"persistent-hint":"","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.hour),callback:function ($$v) {_vm.hour=$$v},expression:"hour"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('validation-provider',{attrs:{"name":"client","rules":"required","vid":"client"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.clients,"item-text":function (item) { return ((item.no) + " - " + (item.nome)); },"item-value":"clstamp","label":((_vm.$t('fields.client')) + " *"),"prepend-inner-icon":_vm.mdiAccountSearch,"error-messages":errors,"outlined":"","dense":"","clearable":"","disabled":!!_vm.pastamp,"loading":_vm.isLoading},model:{value:(_vm.client),callback:function ($$v) {_vm.client=$$v},expression:"client"}})]}}],null,true)})],1),(!_vm.generic)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5","lg":"3"}},[_c('validation-provider',{attrs:{"name":"whoAsk","rules":{ required: !_vm.generic, min: 3 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.whoAskCombo,"label":((_vm.$t('fields.whoAsk')) + " *"),"prepend-inner-icon":_vm.mdiAccountQuestion,"error-messages":errors,"counter":"50","maxlength":"50","outlined":"","dense":"","loading":_vm.isLoading,"clearable":""},model:{value:(_vm.whoAsk),callback:function ($$v) {_vm.whoAsk=$$v},expression:"whoAsk"}})]}}],null,true)})],1):_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5","lg":"3"}},[_c('validation-provider',{attrs:{"name":"name","rules":{ required: _vm.generic, min: 3 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('fields.name')) + " *"),"prepend-inner-icon":_vm.mdiAccountQuestion,"error-messages":errors,"counter":"55","maxlength":"55","outlined":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}],null,true)})],1),(_vm.generic)?[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('validation-provider',{attrs:{"name":"vatNumber","rules":"digits: 9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"type":"number","label":_vm.$t('fields.vatNumber'),"prepend-inner-icon":_vm.mdiCardAccountDetailsOutline,"error-messages":errors,"outlined":"","dense":"","counter":"9"},model:{value:(_vm.vatNumber),callback:function ($$v) {_vm.vatNumber=_vm._n($$v)},expression:"vatNumber"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('validation-provider',{attrs:{"name":"phone","rules":{
              required_if: { target: 'client', client: 'S09012858152,7500000-1' },
              digits: 9
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#########'),expression:"'#########'"}],attrs:{"type":"number","label":((_vm.$t('fields.phone')) + " *"),"prepend-inner-icon":_vm.mdiPhone,"error-messages":errors,"outlined":"","dense":"","counter":"9"},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=_vm._n($$v)},expression:"phone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5","lg":"3"}},[_c('validation-provider',{attrs:{"name":"email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.email'),"prepend-inner-icon":_vm.mdiAt,"error-messages":errors,"counter":"100","maxlength":"100","outlined":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('validation-provider',{attrs:{"name":"address","rules":"min:3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":_vm.$t('fields.address'),"prepend-inner-icon":_vm.mdiHomeOutline,"error-messages":errors,"outlined":"","dense":"","counter":"55","maxlength":"55"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('validation-provider',{attrs:{"name":"postalCode","rules":"regex:^\\d{4}-\\d{3}?$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('####-###'),expression:"'####-###'"}],attrs:{"label":_vm.$t('fields.postalCode'),"prepend-inner-icon":_vm.mdiPost,"error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.postalCode),callback:function ($$v) {_vm.postalCode=$$v},expression:"postalCode"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('validation-provider',{attrs:{"name":"city","rules":{
              required_if: { target: 'client', client: 'S09012858152,7500000-1' },
              min: 3
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":((_vm.$t('fields.city')) + " *"),"prepend-inner-icon":_vm.mdiHomeCityOutline,"error-messages":errors,"outlined":"","counter":"30","maxlength":"30","dense":""},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}})]}}],null,true)})],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5","lg":"3"}},[_c('validation-provider',{attrs:{"name":"technician","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.technicians,"item-text":"cmdesc","item-value":"cm4stamp","label":((_vm.$t('fields.technician')) + " *"),"prepend-inner-icon":_vm.mdiAccountTie,"error-messages":errors,"outlined":"","dense":"","clearable":"","loading":_vm.isLoading},model:{value:(_vm.technician),callback:function ($$v) {_vm.technician=$$v},expression:"technician"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"12","lg":"5"}},[_c('validation-provider',{attrs:{"name":"summary","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.worksCombo,"label":((_vm.$t('fields.summary')) + " *"),"prepend-inner-icon":_vm.mdiPencil,"error-messages":errors,"counter":"70","maxlength":"70","outlined":"","dense":"","loading":_vm.isLoading,"clearable":""},model:{value:(_vm.summary),callback:function ($$v) {_vm.summary=$$v},expression:"summary"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":((_vm.$t('fields.description')) + " *"),"prepend-inner-icon":_vm.mdiText,"error-messages":errors,"counter":"10000","maxlength":"10000","outlined":"","dense":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"internalComunication"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":_vm.$t('fields.internalComunication'),"prepend-inner-icon":_vm.mdiAccountSupervisor,"error-messages":errors,"counter":"10000","maxlength":"10000","outlined":"","dense":""},model:{value:(_vm.internalComunication),callback:function ($$v) {_vm.internalComunication=$$v},expression:"internalComunication"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"color":"secondary","label":((_vm.$t('fields.closed')) + "?")},model:{value:(_vm.closed),callback:function ($$v) {_vm.closed=$$v},expression:"closed"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.isLoading,"disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")])],1)],2)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }