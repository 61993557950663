<template>
  <div>
    <pats-filter @filter="getPatsList"/>
    <v-card elevation="24" class="mt-1">
      <v-card-title>
        <v-spacer/>
        <v-text-field
          v-model="patsSearch"
          :prepend-inner-icon="mdiMagnify"
          :label="$t('aSearch')"
          counter="50"
          maxlength="50"
          clearable
          outlined
          rounded
          dense
          @change="getPatsList"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="patsList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="patsListTotal"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :mobile-breakpoint="0"
          height="80vh"
          fixed-header
          dense
        >
          <template v-slot:item.pdata="{ value }">{{ formatDate(value) }}</template>
          <template v-slot:item.nome="{ item }">
            {{ item.nome }}
            <div class="text-caption">{{ `${$t('shortNumber')} ${item.no}`}}</div>
          </template>
          <template v-slot:item.fechado="{ value }">
            <v-simple-checkbox :value="value" disabled/>
          </template>
          <template v-slot:item.mh_sum_moh="{ value }">
            {{ value || 0 }}
          </template>
          <template v-slot:item.inter="{ item }">
            <span
              v-if="item.mh_count > 0"
              :class="item.done === item.mh_count ? 'success--text' : 'error--text'"
            >
              {{ item.done }}/{{ item.mh_count }}
            </span>
            <span v-else>0</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              color="primary"
              icon
              :to="{ name: 'patInfo', params: { pastamp: item.pastamp } }"
            >
              <v-icon>{{ mdiFormatListBulletedSquare  }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { mdiMagnify, mdiFormatListBulletedSquare } from '@mdi/js';
import { itemsPerPage } from '@/resources/options';
import { patsListHeaders } from '@/resources/headers';
import helpers from '@/resources/helpers';

export default {
  metaInfo() {
    return {
      title: this.$tc('patShort', 2),
    };
  },
  mixins: [helpers],
  components: {
    PatsFilter: () => import(/* webpackChunkName: "support" */ '@/components/filters/PatsFilter.vue'),
  },
  data: () => ({
    itemsPerPage,
    options: {},
    headers: patsListHeaders,
    mdiMagnify,
    mdiFormatListBulletedSquare,
  }),
  computed: {
    ...mapFields(['isLoading']),
    ...mapFields('support', ['patsList', 'patsListTotal', 'patsSearch']),
  },
  watch: {
    options: {
      handler() {
        this.getPatsList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('support', ['fetchPatsList']),
    async getPatsList() {
      this.isLoading = true;

      await this.fetchPatsList(this.options);

      this.isLoading = false;
    },
  },
};
</script>
