<template>
  <router-view :key="$route.fullPath"/>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  computed: {
    ...mapFields(['isLoading']),
  },
  methods: {
    ...mapActions('support', ['fetchTechnicians']),
    ...mapActions('clients', ['fetchClients']),
  },
  async mounted() {
    this.isLoading = true;

    await this.fetchClients();

    await this.fetchTechnicians();

    this.isLoading = false;
  },
};
</script>
