<template>
<div>
  <interventions-chart-filter @filter="getInterventionsData"/>
  <v-card elevation="24" class="mt-1">
    <line-chart
      :chart-options="chartOptions"
      :chart-data="chartData"
      :styles="styles"
    />
  </v-card>
</div>

</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { Line as LineChart } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from 'chart.js';

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
);

export default {
  metaInfo() {
    return {
      title: `${this.$t('analysis')} > ${this.$tc('intervention', 2)}`,
    };
  },
  components: {
    LineChart,
    InterventionsChartFilter: () => import(/* webpackChunkName: "support" */ '@/components/filters/InterventionsChartFilter.vue'),
  },
  data: () => ({
    styles: {
      height: '75vh',
    },
    chartOptions: {
      maintainAspectRatio: false,
    },
  }),
  computed: {
    ...mapFields(['isLoading']),
    ...mapFields('chart', { chartData: 'interventionsData' }),
  },
  methods: {
    ...mapActions('chart', ['fetchInterventionsData']),
    async getInterventionsData() {
      this.isLoading = true;

      await this.fetchInterventionsData();

      this.isLoading = false;
    },
  },
  mounted() {
    this.getInterventionsData();
  },
};
</script>
