var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('interventions-filter',{on:{"filter":_vm.getInterventionsList}}),_c('v-card',{staticClass:"mt-1",attrs:{"elevation":"24"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-inner-icon":_vm.mdiMagnify,"label":_vm.$t('aSearch'),"counter":"50","maxlength":"50","clearable":"","outlined":"","rounded":"","dense":""},on:{"change":_vm.getInterventionsList},model:{value:(_vm.interventionsSearch),callback:function ($$v) {_vm.interventionsSearch=$$v},expression:"interventionsSearch"}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.interventionsList,"loading":_vm.isLoading,"loading-text":_vm.$t('wot.loading'),"options":_vm.options,"server-items-length":_vm.interventionsListTotal,"footer-props":{
          showFirstLastPage: true,
          'items-per-page-options': _vm.itemsPerPage
        },"mobile-breakpoint":0,"height":"80vh","fixed-header":"","dense":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.resumo",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","text":"","plain":"","depressed":"","ripple":false,"to":{ name: 'patInfo', params: { pastamp: item.pa.pastamp } }}},[_vm._v(" #"+_vm._s(item.mhid)+" - "+_vm._s(item.pa.resumo)+", "+_vm._s(item.nome)+" "),_c('div',{staticClass:"text-caption"},[_vm._v("("+_vm._s(((_vm.$t('shortNumber')) + " " + (item.no)))+")")])])]}},{key:"item.data",fn:function(ref){
        var value = ref.value;
return [_vm._v(_vm._s(_vm.formatDate(value)))]}},{key:"item.realizada",fn:function(ref){
        var value = ref.value;
return [(value)?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v(_vm._s(_vm.mdiCheck))])]:[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(_vm._s(_vm.mdiClose))])]]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","to":{
              name: 'editIntervention',
              params: { pastamp: item.pa.pastamp, mhstamp: item.mhstamp }
            }}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiSquareEditOutline))])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.clickDelete(item.mhstamp)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.mdiDeleteOutline))])],1)]}}])})],1)],1),(_vm.deleteDialog)?_c('wot-confirm',{attrs:{"del":true},on:{"confirm-action":_vm.submitDelete}},[_vm._v(" "+_vm._s(_vm.$t('wot.delete'))+" ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }