<template>
  <v-card elevation="24">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-btn color="primary" :to="{ name: 'createIntervention', params: { pastamp } }">
                + {{ $tc('intervention', 1) }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

export default {
  components: {
    ValidationObserver,
  },
  props: {
    pastamp: {
      type: String,
      required: true,
    },
  },
};
</script>
