<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="interventions"
      :loading="isLoading"
      :loading-text="$t('wot.loading')"
      :mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
      dense
    >
      <template v-slot:item.data="{ value }">{{ formatDate(value) }}</template>
      <template v-slot:item.realizada="{ value }">
        <template v-if="value">
          <v-icon color="success">{{ mdiCheck }}</v-icon>
        </template>
        <template v-else>
          <v-icon color="error">{{ mdiClose }}</v-icon>
        </template>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          color="primary"
          :to="{ name: 'editIntervention', params: { pastamp, mhstamp: item.mhstamp } }"
        >
          <v-icon>{{ mdiSquareEditOutline }}</v-icon>
        </v-btn>
        <v-btn icon color="error" @click="clickDelete(item.mhstamp)">
          <v-icon>{{ mdiDeleteOutline }}</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <wot-confirm v-if="deleteDialog" :del="true" @confirm-action="submitDelete">
      {{ $t('wot.delete') }}
    </wot-confirm>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  mdiCheck, mdiClose, mdiSquareEditOutline, mdiDeleteOutline,
} from '@mdi/js';
import { patInterventionsHeaders } from '@/resources/headers';
import helpers from '@/resources/helpers';

export default {
  mixins: [helpers],
  emits: ['delete-intervention'],
  components: {
    WotConfirm: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotConfirm.vue'),
  },
  props: {
    pastamp: {
      type: String,
      required: true,
    },
    interventions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    deleteMhstamp: null,
    deleteDialog: false,
    headers: patInterventionsHeaders,
    mdiCheck,
    mdiClose,
    mdiSquareEditOutline,
    mdiDeleteOutline,
  }),
  computed: {
    ...mapFields(['isLoading', 'alertType']),
  },
  methods: {
    ...mapActions('support', ['deleteIntervention']),
    clickDelete(mhstamp) {
      this.deleteDialog = true;
      this.deleteMhstamp = mhstamp;
    },
    async submitDelete(confirm) {
      if (confirm) {
        this.isLoading = true;

        await this.deleteIntervention(this.deleteMhstamp);

        if (this.alertType === 'success') {
          this.$emit('delete-intervention');
        }

        this.isLoading = false;
      }

      this.deleteDialog = false;
    },
  },
};
</script>
