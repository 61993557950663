<template>
  <div>
    <interventions-filter @filter="getInterventionsList"/>
    <v-card elevation="24" class="mt-1">
      <v-card-title>
        <v-spacer/>
        <v-text-field
          v-model="interventionsSearch"
          :prepend-inner-icon="mdiMagnify"
          :label="$t('aSearch')"
          counter="50"
          maxlength="50"
          clearable
          outlined
          rounded
          dense
          @change="getInterventionsList"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="interventionsList"
          :loading="isLoading"
          :loading-text="$t('wot.loading')"
          :options.sync="options"
          :server-items-length="interventionsListTotal"
          :footer-props="{
            showFirstLastPage: true,
            'items-per-page-options': itemsPerPage
          }"
          :mobile-breakpoint="0"
          height="80vh"
          fixed-header
          dense
        >
          <template v-slot:item.resumo="{ item }">
            <v-btn
              color="primary"
              text
              plain
              depressed
              :ripple="false"
              :to="{ name: 'patInfo', params: { pastamp: item.pa.pastamp } }"
            >
              #{{ item.mhid }} - {{ item.pa.resumo }}, {{ item.nome }}
              <div class="text-caption">({{ `${$t('shortNumber')} ${item.no}`}})</div>
            </v-btn>
          </template>
          <template v-slot:item.data="{ value }">{{ formatDate(value) }}</template>
          <template v-slot:item.realizada="{ value }">
            <template v-if="value">
              <v-icon color="success">{{ mdiCheck }}</v-icon>
            </template>
            <template v-else>
              <v-icon color="error">{{ mdiClose }}</v-icon>
            </template>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              color="primary"
              :to="{
                name: 'editIntervention',
                params: { pastamp: item.pa.pastamp, mhstamp: item.mhstamp }
              }"
            >
              <v-icon>{{ mdiSquareEditOutline }}</v-icon>
            </v-btn>
            <v-btn icon color="error" @click="clickDelete(item.mhstamp)">
              <v-icon>{{ mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <wot-confirm v-if="deleteDialog" :del="true" @confirm-action="submitDelete">
      {{ $t('wot.delete') }}
    </wot-confirm>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import {
  mdiMagnify,
  mdiFormatListBulletedSquare,
  mdiCheck,
  mdiClose,
  mdiSquareEditOutline,
  mdiDeleteOutline,
} from '@mdi/js';
import { itemsPerPage } from '@/resources/options';
import { interventionsList } from '@/resources/headers';
import helpers from '@/resources/helpers';

export default {
  metaInfo() {
    return {
      title: this.$tc('intervention', 2),
    };
  },
  mixins: [helpers],
  components: {
    InterventionsFilter: () => import(/* webpackChunkName: "support" */ '@/components/filters/InterventionsFilter.vue'),
    WotConfirm: () => import(/* webpackChunkName: "wot" */ '@/components/wot/WotConfirm.vue'),
  },
  data: () => ({
    deleteMhstamp: null,
    deleteDialog: false,
    itemsPerPage,
    options: {},
    headers: interventionsList,
    mdiMagnify,
    mdiFormatListBulletedSquare,
    mdiCheck,
    mdiClose,
    mdiSquareEditOutline,
    mdiDeleteOutline,
  }),
  computed: {
    ...mapFields(['isLoading', 'alertType']),
    ...mapFields('support', ['interventionsList', 'interventionsListTotal', 'interventionsSearch']),
  },
  watch: {
    options: {
      handler() {
        this.getInterventionsList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('support', ['fetchInterventionsList', 'deleteIntervention']),
    async getInterventionsList() {
      this.isLoading = true;

      await this.fetchInterventionsList(this.options);

      this.isLoading = false;
    },
    clickDelete(mhstamp) {
      this.deleteDialog = true;
      this.deleteMhstamp = mhstamp;
    },
    async submitDelete(confirm, mhstamp) {
      if (confirm) {
        this.isLoading = true;

        await this.deleteIntervention(mhstamp);

        if (this.alertType === 'success') {
          this.getInterventionsList();
        }

        this.isLoading = false;
      }

      this.deleteDialog = false;
    },
  },
};
</script>
