<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <v-form @submit.prevent="handleSubmit(submitForm)" ref="form">
      <v-row>
        <v-col cols="12" sm="6" md="2">
          <v-menu
            ref="menu"
            v-model="datePicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider v-slot="{ errors }" name="date" rules="required">
                <v-text-field
                  :value="dateFormatted"
                  :label="`${$t('fields.date')} *`"
                  :prepend-inner-icon="mdiCalendar"
                  :hint="$t('wot.formats.date')"
                  persistent-hint
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="errors"
                  outlined
                  dense
                  readonly
                />
              </validation-provider>
            </template>
            <v-date-picker
              v-model="date"
              @input="datePicker = false"
              no-title
              show-adjacent-months
              first-day-of-week="1"
            >
              <v-btn text block color="primary" @click="clickToday()">{{ $t('today') }}</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="6" md="2">
          <validation-provider
            v-slot="{ errors }"
            name="hour"
            rules="required|regex:[0-9]{2}:[0-9]{2}"
          >
            <v-text-field
              v-model="hour"
              :label="`${$t('fields.hour')} *`"
              v-mask="'##:##'"
              :prepend-inner-icon="mdiClockOutline"
              :hint="$t('wot.formats.hourMin')"
              persistent-hint
              :error-messages="errors"
              outlined
              dense
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <validation-provider v-slot="{ errors }" name="client" rules="required" vid="client">
            <v-autocomplete
              v-model="client"
              :items="clients"
              :item-text="item =>`${item.no} - ${item.nome}`"
              item-value="clstamp"
              :label="`${$t('fields.client')} *`"
              :prepend-inner-icon="mdiAccountSearch"
              :error-messages="errors"
              outlined
              dense
              clearable
              :disabled="!!pastamp"
              :loading="isLoading"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="5" lg="3" v-if="!generic">
          <validation-provider
            v-slot="{ errors }"
            name="whoAsk"
            :rules="{ required: !generic, min: 3 }"
          >
            <v-combobox
              v-model="whoAsk"
              :items="whoAskCombo"
              :label="`${$t('fields.whoAsk')} *`"
              :prepend-inner-icon="mdiAccountQuestion"
              :error-messages="errors"
              counter="50"
              maxlength="50"
              outlined
              dense
              :loading="isLoading"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="5" lg="3" v-else>
          <validation-provider
            v-slot="{ errors }"
            name="name"
            :rules="{ required: generic, min: 3 }"
          >
            <v-text-field
              v-model="name"
              :label="`${$t('fields.name')} *`"
              :prepend-inner-icon="mdiAccountQuestion"
              :error-messages="errors"
              counter="55"
              maxlength="55"
              outlined
              dense
            />
          </validation-provider>
        </v-col>
        <template v-if="generic">
          <v-col cols="12" sm="6" md="3" lg="2">
            <validation-provider v-slot="{ errors }" name="vatNumber" rules="digits: 9">
              <v-text-field
                type="number"
                v-mask="'#########'"
                v-model.number="vatNumber"
                :label="$t('fields.vatNumber')"
                :prepend-inner-icon="mdiCardAccountDetailsOutline"
                :error-messages="errors"
                outlined
                dense
                counter="9"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <validation-provider
              v-slot="{ errors }"
              name="phone"
              :rules="{
                required_if: { target: 'client', client: 'S09012858152,7500000-1' },
                digits: 9
              }"
            >
              <v-text-field
                type="number"
                v-mask="'#########'"
                v-model.number="phone"
                :label="`${$t('fields.phone')} *`"
                :prepend-inner-icon="mdiPhone"
                :error-messages="errors"
                outlined
                dense
                counter="9"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="5" lg="3">
            <validation-provider v-slot="{ errors }" name="email" rules="email">
              <v-text-field
                v-model="email"
                :label="$t('fields.email')"
                :prepend-inner-icon="mdiAt"
                :error-messages="errors"
                counter="100"
                maxlength="100"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="3">
            <validation-provider v-slot="{ errors }" name="address" rules="min:3">
              <v-text-field
                v-model="address"
                :label="$t('fields.address')"
                :prepend-inner-icon="mdiHomeOutline"
                :error-messages="errors"
                outlined
                dense
                counter="55"
                maxlength="55"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <validation-provider
              v-slot="{ errors }"
              name="postalCode"
              rules="regex:^\d{4}-\d{3}?$"
            >
              <v-text-field
                v-model="postalCode"
                :label="$t('fields.postalCode')"
                v-mask="'####-###'"
                :prepend-inner-icon="mdiPost"
                :error-messages="errors"
                outlined
                dense
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="6" md="3" lg="2">
            <validation-provider
              v-slot="{ errors }"
              name="city"
              :rules="{
                required_if: { target: 'client', client: 'S09012858152,7500000-1' },
                min: 3
              }"
            >
              <v-text-field
                v-model="city"
                :label="`${$t('fields.city')} *`"
                :prepend-inner-icon="mdiHomeCityOutline"
                :error-messages="errors"
                outlined
                counter="30"
                maxlength="30"
                dense
              />
            </validation-provider>
          </v-col>
        </template>
        <v-col cols="12" sm="6" md="5" lg="3">
          <validation-provider v-slot="{ errors }" name="technician" rules="required">
            <v-autocomplete
              v-model="technician"
              :items="technicians"
              item-text="cmdesc"
              item-value="cm4stamp"
              :label="`${$t('fields.technician')} *`"
              :prepend-inner-icon="mdiAccountTie"
              :error-messages="errors"
              outlined
              dense
              clearable
              :loading="isLoading"
            />
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6" md="12" lg="5">
          <validation-provider v-slot="{ errors }" name="summary" rules="required">
            <v-combobox
              v-model="summary"
              :items="worksCombo"
              :label="`${$t('fields.summary')} *`"
              :prepend-inner-icon="mdiPencil"
              :error-messages="errors"
              counter="70"
              maxlength="70"
              outlined
              dense
              :loading="isLoading"
              clearable
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider v-slot="{ errors }" name="description" rules="required">
            <v-textarea
              v-model="description"
              :label="`${$t('fields.description')} *`"
              :prepend-inner-icon="mdiText"
              :error-messages="errors"
              counter="10000"
              maxlength="10000"
              outlined
              dense
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <validation-provider v-slot="{ errors }" name="internalComunication">
            <v-textarea
              v-model="internalComunication"
              :label="$t('fields.internalComunication')"
              :prepend-inner-icon="mdiAccountSupervisor"
              :error-messages="errors"
              counter="10000"
              maxlength="10000"
              outlined
              dense
            />
          </validation-provider>
        </v-col>
        <v-col cols="12">
          <v-checkbox
            color="secondary"
            v-model="closed"
            :label="`${$t('fields.closed')}?`"
          />
        </v-col>
        <v-col cols="12">
          <v-btn color="primary" type="submit" :loading="isLoading" :disabled="isLoading">
            {{ btnText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { VueMaskDirective } from 'v-mask';
import {
  mdiCalendar,
  mdiClockOutline,
  mdiAccountSupervisor,
  mdiPencil,
  mdiText,
  mdiAccountSearch,
  mdiAccountQuestion,
  mdiCardAccountDetailsOutline,
  mdiPhone,
  mdiHomeOutline,
  mdiPost,
  mdiHomeCityOutline,
  mdiAccountTie,
  mdiAt,
} from '@mdi/js';
import dayjs from '@/plugins/dayjs';
import helpers from '@/resources/helpers';

export default {
  metaInfo() {
    return {
      title: this.pastamp ? `${this.$tc('patShort', 1)} #${this.patInfo.nopat}` : this.$t('createPat'),
    };
  },
  directives: { mask: VueMaskDirective },
  mixins: [helpers],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    pastamp: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    datePicker: false,
    mdiCalendar,
    mdiClockOutline,
    mdiAccountSupervisor,
    mdiPencil,
    mdiText,
    mdiAccountSearch,
    mdiAccountQuestion,
    mdiCardAccountDetailsOutline,
    mdiPhone,
    mdiHomeOutline,
    mdiPost,
    mdiHomeCityOutline,
    mdiAccountTie,
    mdiAt,
  }),
  computed: {
    ...mapFields({ patFormErrors: 'errors.pat', isLoading: 'isLoading', alertType: 'alertType' }),
    ...mapFields('support', { whoAskCombo: 'whoAsk', worksCombo: 'works' }),
    ...mapFields(
      'support',
      [
        'technicians',
        'patForm',
        'patInfo',
        'patForm.date',
        'patForm.hour',
        'patForm.technician',
        'patForm.summary',
        'patForm.description',
        'patForm.internalComunication',
        'patForm.client',
        'patForm.whoAsk',
        'patForm.closed',
        'patForm.name',
        'patForm.vatNumber',
        'patForm.phone',
        'patForm.email',
        'patForm.address',
        'patForm.postalCode',
        'patForm.city',
      ],
    ),
    ...mapFields('clients', ['clients']),
    btnText() {
      return this.pastamp ? this.$t('aSave') : this.$t('create');
    },
    dateFormatted() {
      return this.formatDate(this.date);
    },
    generic() {
      // cl.no = 138
      return this.client === 'S09012858152,7500000-1';
    },
  },
  methods: {
    ...mapActions('support', ['createPat', 'fetchPat', 'editPat', 'fetchWhoAsk', 'fetchWorks']),
    clickToday() {
      this.date = dayjs().format('YYYY-MM-DD');
    },
    async submitForm() {
      this.isLoading = true;

      if (this.pastamp) {
        await this.editPat(this.pastamp);
      } else {
        await this.createPat();

        if (this.alertType === 'success') {
          this.$router.push({ name: 'pats' });
        }
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    this.isLoading = true;

    await this.fetchWhoAsk();
    await this.fetchWorks();

    if (this.pastamp) {
      await this.fetchPat(this.pastamp);

      const {
        pdata,
        phora,
        resumo,
        problema,
        just,
        pquem,
        cl,
        fechado,
        local,
        morada,
        nome,
        ncont,
        telefone,
        remail,
        codpost,
        cm4tstamp,
      } = this.patInfo;

      this.date = pdata;
      this.hour = phora;
      this.technician = cm4tstamp;
      this.summary = resumo;
      this.description = problema;
      this.internalComunication = just;
      this.client = cl?.clstamp;
      this.whoAsk = pquem;
      this.closed = fechado;
      this.name = nome;
      this.vatNumber = ncont;
      this.phone = telefone;
      this.email = remail;
      this.address = morada;
      this.postalCode = codpost;
      this.city = local;
    } else {
      this.date = dayjs().format('YYYY-MM-DD');
      this.hour = dayjs().format('HH:mm');
      this.technician = JSON.parse(localStorage.getItem('config'))?.technician;
      this.summary = '';
      this.description = '';
      this.internalComunication = '';
      this.client = '';
      this.whoAsk = '';
      this.closed = false;
      this.name = '';
      this.vatNumber = '';
      this.phone = '';
      this.email = '';
      this.address = '';
      this.postalCode = '';
      this.city = '';
    }

    this.isLoading = false;
  },
  watch: {
    patFormErrors: {
      deep: true,
      handler(value) {
        this.$refs.observer.setErrors(value);
      },
    },
  },
};
</script>
