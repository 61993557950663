import i18n from '@/plugins/i18n';

const ordersListHeaders = [
  {
    text: i18n.tc('document', 1), value: 'document', align: 'right', sortable: false,
  },
  { text: i18n.t('date'), value: 'dataobra', align: 'center' },
  { text: i18n.tc('client', 1), value: 'nome' },
  {
    text: i18n.t('cut'), value: 'u_stcorte', align: 'center', sortable: false,
  },
  {
    text: i18n.t('laminate'), value: 'u_stlamina', align: 'center', sortable: false,
  },
  {
    text: i18n.t('cutInt'), value: 'u_stinter', align: 'center', sortable: false,
  },
  {
    text: i18n.t('cutDec'), value: 'u_stdecor', align: 'center', sortable: false,
  },
  {
    text: i18n.t('sealing'), value: 'u_stselag', align: 'center', sortable: false,
  },
  {
    text: i18n.t('edge'), value: 'u_staresta', align: 'center', sortable: false,
  },
  {
    text: i18n.t('notch'), value: 'u_stentalh', align: 'center', sortable: false,
  },
  {
    text: i18n.t('hole'), value: 'u_stfuro', align: 'center', sortable: false,
  },
  {
    text: i18n.t('tempering'), value: 'u_sttemp', align: 'center', sortable: false,
  },
  {
    text: i18n.t('matte'), value: 'u_stfoscag', align: 'center', sortable: false,
  },
  {
    text: i18n.t('av'), value: 'u_stav', align: 'center', sortable: false,
  },
  {
    text: i18n.t('ku'), value: 'u_stku', align: 'center', sortable: false,
  },
];

const ordersActionHeaders = [
  { text: i18n.t('designation'), value: 'design' },
  { text: i18n.tc('measurement', 2), value: 'measurements', sortable: false },
  { text: i18n.t('qttGlass'), value: 'partes', align: 'right' },
  { text: i18n.t('realizationDate'), value: 'date', align: 'center' },
  { text: i18n.tc('operator', 1), value: 'operator' },
  {
    text: i18n.tc('note', 2),
    value: 'lobsauto',
    width: '450px',
    sortable: false,
  },
  { value: 'data-table-select' },
];

const patsListHeaders = [
  { value: 'actions', align: 'center', sortable: false },
  { text: '#', value: 'nopat', align: 'right' },
  { text: i18n.t('fields.date'), value: 'pdata', align: 'center' },
  { text: i18n.t('fields.technician'), value: 'tecnnm' },
  { text: i18n.t('fields.client'), value: 'nome' },
  { text: i18n.t('fields.whoAsk'), value: 'pquem' },
  { text: i18n.t('fields.whoOpen'), value: 'ousrinis' },
  { text: i18n.t('fields.summary'), value: 'resumo' },
  {
    text: i18n.t('fields.description'),
    value: 'problema',
    sortable: false,
    cellClass: 'text-truncate',
  },
  {
    text: i18n.t('fields.internalComunication'),
    value: 'just',
    sortable: false,
    cellClass: 'text-truncate',
  },
  {
    text: i18n.tc('mClosed', 1),
    value: 'fechado',
    align: 'center',
    sortable: false,
  },
  {
    text: i18n.tc('hour', 2),
    value: 'mh_sum_moh',
    align: 'right',
    sortable: false,
  },
  {
    text: i18n.tc('intervention', 2),
    value: 'inter',
    align: 'right',
    sortable: false,
  },
];

const interventionsList = [
  { value: 'actions', align: 'center', sortable: false },
  { text: '#', value: 'mhid', align: 'right' },
  { text: i18n.t('fields.summary'), value: 'resumo', sortable: false },
  { text: i18n.t('fields.date'), value: 'data', align: 'center' },
  { text: i18n.t('fields.technician'), value: 'tecnnm' },
  {
    text: i18n.t('fields.startHour'),
    value: 'hora',
    align: 'right',
    sortable: false,
  },
  {
    text: i18n.t('fields.endHour'),
    value: 'horaf',
    align: 'right',
    sortable: false,
  },
  {
    text: i18n.t('fields.report'),
    value: 'relatorio',
    cellClass: 'text-truncate',
    sortable: false,
  },
  {
    text: i18n.t('fields.fulfilled'),
    value: 'realizada',
    align: 'center',
    sortable: false,
  },
];

const patInterventionsHeaders = [
  { value: 'actions', align: 'center', sortable: false },
  { text: '#', value: 'mhid', align: 'right' },
  { text: i18n.t('fields.date'), value: 'data', align: 'center' },
  { text: i18n.t('fields.technician'), value: 'tecnnm' },
  {
    text: i18n.t('fields.startHour'),
    value: 'hora',
    align: 'right',
    sortable: false,
  },
  {
    text: i18n.t('fields.endHour'),
    value: 'horaf',
    align: 'right',
    sortable: false,
  },
  { text: i18n.t('fields.report'), value: 'relatorio', cellClass: 'text-truncate' },
  {
    text: i18n.t('fields.fulfilled'),
    value: 'realizada',
    align: 'center',
    sortable: false,
  },
];

export {
  ordersListHeaders,
  ordersActionHeaders,
  patsListHeaders,
  patInterventionsHeaders,
  interventionsList,
};
