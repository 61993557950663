<template>
  <v-card elevation="24">
    <v-card-text>
      <validation-observer ref="observer">
        <v-form>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="2">
              <validation-provider name="closed">
                <v-select
                  v-model="closed"
                  :items="closedOptions"
                  :prepend-inner-icon="closedIcon"
                  item-text="text"
                  item-value="value"
                  outlined
                  dense
                />
              </validation-provider>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn color="primary" :to="{ name: 'createPat' }">+ {{ $tc('patShort', 1) }}</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapFields } from 'vuex-map-fields';
import { mdiLockOutline, mdiLockOpenVariantOutline, mdiListStatus } from '@mdi/js';
import helpers from '@/resources/helpers';

export default {
  mixins: [helpers],
  emits: ['filter'],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      closedOptions: [
        { value: false, text: this.$tc('mOpen', 2) },
        { value: true, text: this.$tc('mClosed', 2) },
        { value: null, text: this.$t('mAll') },
      ],
    };
  },
  computed: {
    ...mapGetters('support', ['patsFilter']),
    ...mapFields('support', { closed: 'filters.pats.closed' }),
    closedIcon() {
      switch (this.closed) {
        case true:
          return mdiLockOutline;
        case false:
          return mdiLockOpenVariantOutline;
        default:
          return mdiListStatus;
      }
    },
  },
  watch: {
    patsFilter: {
      deep: true,
      handler() {
        this.$emit('filter');
      },
    },
  },
};
</script>
