var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateFormatted,"label":((_vm.$t('fields.date')) + " *"),"prepend-inner-icon":_vm.mdiCalendar,"hint":_vm.$t('wot.formats.date'),"persistent-hint":"","error-messages":errors,"outlined":"","dense":"","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"no-title":"","show-adjacent-months":"","first-day-of-week":"1"},on:{"input":function($event){_vm.datePicker = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-btn',{attrs:{"text":"","block":"","color":"primary"},on:{"click":function($event){return _vm.clickToday()}}},[_vm._v(_vm._s(_vm.$t('today')))])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('validation-provider',{attrs:{"name":"startHour","rules":"required|regex:[0-9]{2}:[0-9]{2}"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],attrs:{"label":((_vm.$t('fields.startHour')) + " *"),"prepend-inner-icon":_vm.mdiClockOutline,"hint":_vm.$t('wot.formats.hourMin'),"persistent-hint":"","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.startHour),callback:function ($$v) {_vm.startHour=$$v},expression:"startHour"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('validation-provider',{attrs:{"name":"endHour","rules":"required|regex:[0-9]{2}:[0-9]{2}"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##:##'),expression:"'##:##'"}],attrs:{"label":((_vm.$t('fields.endHour')) + " *"),"prepend-inner-icon":_vm.mdiClockOutline,"hint":_vm.$t('wot.formats.hourMin'),"persistent-hint":"","error-messages":errors,"outlined":"","dense":""},model:{value:(_vm.endHour),callback:function ($$v) {_vm.endHour=$$v},expression:"endHour"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5","lg":"3"}},[_c('validation-provider',{attrs:{"name":"technician","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"items":_vm.technicians,"item-text":"cmdesc","item-value":"cm4stamp","label":((_vm.$t('fields.technician')) + " *"),"prepend-inner-icon":_vm.mdiAccountTie,"error-messages":errors,"outlined":"","dense":"","clearable":"","loading":_vm.isLoading},model:{value:(_vm.technician),callback:function ($$v) {_vm.technician=$$v},expression:"technician"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"report","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":((_vm.$t('fields.report')) + " *"),"prepend-inner-icon":_vm.mdiText,"error-messages":errors,"counter":"10000","maxlength":"10000","outlined":"","dense":""},model:{value:(_vm.report),callback:function ($$v) {_vm.report=$$v},expression:"report"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-checkbox',{attrs:{"color":"secondary","label":((_vm.$t('fields.fulfilled')) + "?")},model:{value:(_vm.fulfilled),callback:function ($$v) {_vm.fulfilled=$$v},expression:"fulfilled"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.isLoading,"disabled":_vm.isLoading}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }